import React, { Component } from 'react'
import DynamicComponent from "./DynamicComponent"
import SbEditable from 'storyblok-react'
import CloudinaryVideo from './cloudinaryvideoembed'
import { CSSRulePlugin } from "gsap/CSSRulePlugin"
import { CSSPlugin } from "gsap/CSSPlugin"
import { gsap } from 'gsap'

import { ScrollTrigger } from "gsap/ScrollTrigger"
import Scrollbar from "smooth-scrollbar"
import { isMobile, isTablet } from "react-device-detect"
import Footer from './Footer'
import SampleForm from './SampleForm'


class RequestASample extends Component {
  constructor(props) {
    super(props)
    this.state = {
      producttype: "All",
      products: [],
    }

  }

  componentDidMount() {
    window.addEventListener("scroll", function(e) {
      if(window.scrollY > window.innerHeight) {
        document.body.classList.add("invert-header")
      } else {
        document.body.classList.remove("invert-header")
      }
    })
    

  }



  render() {
    const blok = this.props.blok
    const content =
      blok.body &&
      blok.body.map(childBlok => <DynamicComponent blok={childBlok} key={childBlok._uid}/>)

    return (
      <SbEditable content={blok}>
        {(blok.BackgroundVideo) ?
          <div className="bg-video">
            <CloudinaryVideo
              publicId={`ykk/${blok.BackgroundVideo}`}
              class_list="cover-bg"
              autoPlay={true}
            />
          </div>
          : ''}
          
          { content }
          <SampleForm />
          <Footer />
      </SbEditable>
    )
  }

}

export default RequestASample
