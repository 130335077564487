import React from 'react'

function ProductLocationList() {
  return (
    <>
  		<option>Multiple Locations</option><option>Austria</option><option>Belgium</option><option>Bulgaria</option><option>Czech Republic</option><option>Denmark</option><option>Egypt</option><option>Finland</option><option>France</option><option>Germany</option><option>Greece</option><option>Hungary</option><option>Ireland</option><option>Italy</option><option>Jordan</option><option>Kazakhistan</option><option>Kenya</option><option>Lebanon</option><option>Lithuania</option><option>Mauritius</option><option>Morocco</option><option>Netherlands</option><option>Norway</option><option>Poland</option><option>Portugal</option><option>Romania</option><option>Russia</option><option>Serbia</option><option>South Africa</option><option>Spain</option><option>Sweden</option><option>Tunisia</option><option>Turkey</option><option>UAE (United Arab Emirate)</option><option>United Kingdom</option><option>Ukraine</option><option>America</option><option>Asia</option><option>Other</option>
  	</>
  )
}

export default ProductLocationList
